import React from "react";

const Tag = ({ tagName }) => {
  const styles = {
    display: "inline-block",
    background: "#5F50E4",
    borderRadius: "2px",
    width: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "9px",
    position: "absolute",
    top: "-8px",
    right: "-30px",
  };
  return <div style={styles}>{tagName}</div>;
};

export default Tag;
