import React, { useEffect, useState } from "react";
import { simpleRequest } from "../../utils/api";
import Layout from "../../templates/layout";
import PricingLoading from "../../components/pricing-components/pricing-loading";
import PricingError from "../../components/pricing-components/pricing-error";
import PricingTable from "../../components/pricing-components/pricing-table";
import SetTitle from "../../components/set-title";
import TestimonialList from "../../components/testimonial-list";
import ExpandableCard from "../../components/faq-card";
import faqs from "../../components/faqs";
import PeopleReviews from "../../components/people-reviews";

let timer;
const calculateCountDownString = (endTime) => {
  const timeLeft = new Date(endTime) - new Date();
  const atleast2Digit = (param) => String(param).padStart(2, 0);
  const days = atleast2Digit(Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
  const hours = atleast2Digit(
    Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const minutes = atleast2Digit(
    Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
  );
  const seconds = atleast2Digit(Math.floor((timeLeft % (1000 * 60)) / 1000));
  let countdownString = `${days}:${hours}:${minutes}:${seconds}`;
  return countdownString;
};

const Pricing = () => {
  // pending, error, success
  const [tickFeatures, setTickFeatures] = useState([]);
  const [planLoadState, setPlanLoadState] = useState("pending");
  const [plans, setPlans] = useState([]);
  const [countDown, setCountDown] = useState({});
  const [countDownString, setCountDownString] = useState("00:00:00:00");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setPlanLoadState("pending");
    simpleRequest({
      url: "/pricingparams",
      method: "GET",
    })
      .then((response) => {
        const {
          plansObject,
          countDownTimerObject = {},
          featuresObject: features,
          messagesArray,
        } = response.data;
        setPlans(plansObject);
        setTickFeatures(features);
        setMessages(messagesArray);

        const timeRightNow = Math.floor(new Date().getTime() / 1000);
        let { start, end } = countDownTimerObject;
        if (timeRightNow < end && timeRightNow > start) {
          end *= 1000;
          countDownTimerObject.end = end;
          setCountDown(countDownTimerObject);
          timer = setInterval(() => {
            setCountDownString(calculateCountDownString(end));
          }, 1000);
        }
        if (plansObject.length > 0) {
          setPlanLoadState("success");
          return plansObject;
        } else {
          setPlanLoadState("error");
          throw new Error();
        }
      })
      .catch(() => {
        setPlanLoadState("error");
      });

    return () => {
      clearInterval(timer);
    };
  }, []);

  let timeSpan = (index) => {
    switch (index) {
      case 0:
        return "DAYS";
      case 1:
        return "HOURS";
      case 2:
        return "MINS";
      case 3:
        return "SEC";
    }
  };
  return (
    <Layout newFooter={true}>
      <SetTitle title="Pricing | VdoCipher" />
      <div className="content pricing-page">
        <div className="ptb ptb-xs ptb-sm pricing-section">
          {countDown.message && (
            <div className="row discount-box">
              <div className="message col m6 s12">{countDown.message}</div>
              <div className="timer-wrap col m6 s12">
                {countDownString.split(":").map((eachValue, i) => (
                  <div
                    className={`${
                      i == 0 && eachValue == "00"
                        ? "display-none"
                        : "display-block"
                    }`}
                    key={timeSpan(i)}
                  >
                    {timeSpan(i)} <br /> <strong>{eachValue}</strong>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* to be replaced with new design */}
          {messages.map((message) => (
            <div className="row discount-box" key={message}>
              <div className="message col m12 s12">
                <div className="timer-wrap col m12 s12">
                  <strong
                    dangerouslySetInnerHTML={{ __html: message }}
                  ></strong>
                </div>
              </div>
            </div>
          ))}
          <div className="container">
            <div className="row" style={{ display: "flex" }}>
              {planLoadState === "pending" && <PricingLoading />}
              {planLoadState === "error" && <PricingError />}
              {planLoadState === "success" && (
                <PricingTable plans={plans} tickFeatures={tickFeatures} />
              )}
            </div>
          </div>
        </div>
        {
          <div className="container pricing-question row pricing-section max-container">
            <p>General Questions</p>
            <ExpandableCard faqList={faqs(["faq-04"])} />
          </div>
        }

        <div
          className="pricing-section bg-whitish-blue"
          style={{ paddingBottom: "50px" }}
        >
          <PeopleReviews />
        </div>
        <div className="pricing-section container">
          <TestimonialList />
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
