import React from "react";
import blueCheck from "../../svg/svgs/blue-check.svg";
import none from "../../svg/svgs/none.svg";
import PricingLite from "./pricing-lite";
import PricingTooltip from "./pricing-tooltip";

const PricingDesktop = ({
  plans,
  tickFeatures,
  buyButton,
  enterprisePlanObj,
}) => {
  // Dividing the Talbe Width into  `2.3fr repeat(${plans?.length},1fr) 1.1fr`
  const labelsWidth = `${(2.3 * 100) / (2.3 + plans.length + 1.1)}%`;
  const regularPlansWidth = `${(1 * 100) / (2.3 + plans.length + 1.1)}%`;
  const enterprisePlanWidth = `${(1.1 * 100) / (2.3 + plans.length + 1.1)}%`;

  return (
    <table className="pricing-table-desktop border-left-none" border="1">
      <tbody>
        {/* Heading */}
        <tr className="table-row">
          <th className="border-left-none" width={labelsWidth} rowSpan={4}>
            <PricingLite buyButton={buyButton} />
          </th>
          {plans.map((eachPlanObj) => (
            <th
              className={`${eachPlanObj.codename.toLowerCase()}`}
              key={`th_${eachPlanObj.id}`}
              width={regularPlansWidth}
            >
              {eachPlanObj.codename.toUpperCase()}
            </th>
          ))}
          <th
            className={`${enterprisePlanObj.codename.toLowerCase()}`}
            width={enterprisePlanWidth}
          >
            {enterprisePlanObj.codename.toUpperCase()}
          </th>
        </tr>

        {/* Non-Discounted Amount*/}
        <tr className="table-row">
          {plans.map((eachPlanObj) => (
            <td
              className="border-y-none non-discount-amt sub-value"
              key={`nonDiscountedAmount_${eachPlanObj.id}`}
            >
              {eachPlanObj.non_discount_amount_usd
                ? `$ ${eachPlanObj.non_discount_amount_usd} /year`
                : ""}
            </td>
          ))}
          <td className="border-y-none border-left-none"></td>
        </tr>

        {/* Discount */}
        <tr className="table-row">
          {plans.map((eachPlanObj) => (
            <td
              className=" border-y-none vertical-align-top"
              key={`discount_${eachPlanObj.id}`}
            >
              <div className="discount">
                <span className="amt">{`$${eachPlanObj.amount_usd}`}</span>
                <span>{`/year`}</span>
              </div>
            </td>
          ))}
          <td className="border-y-none vertical-align-top">
            <div className="discount">
              <span className="amt">Custom</span>
              <span>for larger scale</span>
            </div>
          </td>
        </tr>

        {/* Free Trial button */}
        <tr className="table-row">
          {plans.map((eachPlanObj) => (
            <td className=" border-y-none" key={`buyBtn_${eachPlanObj.id}`}>
              <a href={buyButton.href} className="plan-buy-button">
                {buyButton.text}
              </a>
            </td>
          ))}
          <td className=" border-y-none ">
            <a href="/site/contact" className="plan-buy-button">
              Contact Us
            </a>
          </td>
        </tr>

        {/* Bandwidth and Validity */}
        <tr className="table-row background-grey">
          <td className="border-y-none border-left-none feature-label vertical-align-top position-relative ">
            <strong>Bandwidth, 1 year validity&nbsp;</strong>
            <PricingTooltip
              featureContent={` <div>
                Bandwidth is data consumed based on actual consumption of
                video data by all your viewers&nbsp;
                <br />
                <a
                  href="/blog/bandwidth-storage-calculation/"
                  style={{ color: "#5f50e4" }}
                  target="_blank"
                >
                  Detailed explanation here.
                </a>
              </div>`}
            />
          </td>
          {plans.map((eachPlanObj) => (
            <td
              className="border-y-none vertical-align-top"
              key={`bandwidthValidity_${eachPlanObj.id}`}
            >
              <strong className="highlight-feature">
                {eachPlanObj.bandwidth}
              </strong>
              <p className="sub-value">{eachPlanObj.validity}</p>
            </td>
          ))}
          <td
            className="border-y-none vertical-align-top padding-y-none"
            rowSpan={3}
          >
            <ul>
              <li>Account Manager</li>
              <li>Whatsapp & Call Support</li>
              <li>Scale to 10 Million Viewers</li>
              <li>Private APIs & User Based Analytics</li>
              <li>SLA, 99.94% uptime</li>
            </ul>
          </td>
        </tr>

        {/* Estimated Hours */}
        <tr className="table-row background-grey">
          <td className="border-y-none border-left-none feature-label vertical-align-top position-relative">
            <>
              Estimated hours streaming (800kbps) &nbsp;
              <PricingTooltip
                featureContent={`<div>
                  Estimate watch time based on 800 kbps video bitrate, which
                  is about 360 MB/hour of data.
                </div>`}
              />
            </>
          </td>
          {plans.map((eachPlanObj) => (
            <td
              className="sub-value border-y-none vertical-align-top"
              key={`estHrs_${eachPlanObj.id}`}
            >
              {eachPlanObj.approxHoursNumeric ? (
                `Estimated hours~${eachPlanObj.approxHoursNumeric}`
              ) : (
                <>&#8212;</>
              )}
            </td>
          ))}
          {/* rownSpan */}
          {/* <td className="sub-value border-y-none vertical-align-top"></td> */}
        </tr>

        {/* Storage */}
        <tr className="table-row background-grey">
          <td className="border-y-none border-left-none feature-label vertical-align-top position-relative">
            <>
              <strong>Storage</strong> (Accounted storage can be 2 to 4 times of
              upload, files for device & quality) &nbsp;
              <PricingTooltip
                featureContent={`<div>
                  We create multiple encryption for android, iOS and
                  desktop; and also multiple quality files; thus actual
                  storage is higher than uploaded.
                </div>`}
              />
            </>
          </td>
          {plans.map((eachPlanObj) => (
            <td
              className="border-y-none vertical-align-top"
              key={`storage_${eachPlanObj.id}`}
            >
              <strong className="highlight-feature ">
                {eachPlanObj.max_storage ? (
                  `${eachPlanObj.max_storage}`
                ) : (
                  <>&#8212;</>
                )}
              </strong>
            </td>
          ))}
          {/* rownSpan */}
          {/* <td className="border-y-none  sub-value vertical-align-top"></td> */}
        </tr>
      </tbody>

      {/* Extra Bandwidth */}
      <tr className="table-row background-grey">
        <td className=" border-left-none feature-label">
          <>
            Extra/Overage bandwidth price. Card is never autocharged.&nbsp;
            <PricingTooltip
              featureContent={`<div>
                When your bandwidth is about getting over, you can buy more
                bandwidth directly from dashboard. Account will not be
                suddenly stopped without warnings.
              </div>`}
            />
          </>
        </td>
        {plans.map((eachPlanObj) => (
          <td key={`extraBandwidth_${eachPlanObj.id}`}>
            <strong className="highlight-feature">
              {eachPlanObj.overageBandwidth ? (
                `$${eachPlanObj.overageBandwidth}/GB`
              ) : (
                <>&#8212;</>
              )}
            </strong>
          </td>
        ))}
        <td
          className="border-y-none vertical-align-top padding-y-none"
          rowSpan={2}
        >
          <ul>
            <li>Better Bandwidth price</li>
            <li>Multi-Tenant Features for Course/Website Builders</li>
            <a href="https://www.vdocipher.com/enterprise/" target="_blank">
              More Details here
            </a>
          </ul>
        </td>
      </tr>

      {/* Extra Storage */}
      <tr className="table-row background-grey">
        <td className=" border-left-none padding-y-14 feature-label">
          <>Extra/Overage storage price &nbsp;</>
        </td>
        {plans.map((eachPlanObj) => (
          <td className="padding-y-14" key={`extraStorage_${eachPlanObj.id}`}>
            <strong className="highlight-feature ">
              {eachPlanObj.overageStorage ? (
                `$${eachPlanObj.overageStorage}/GB`
              ) : (
                <>&#8212;</>
              )}
            </strong>
          </td>
        ))}
        {/* rowSpan */}
        {/* <td className="border-y-none vertical-align-top padding-y-none">
        </td> */}
      </tr>

      {/* All other Tick Features */}
      {tickFeatures?.length > 0 &&
        tickFeatures.map((eachBooleanFeatures) => {
          return (
            <tr
              className="table-row bg-hover"
              key={`${eachBooleanFeatures.label}`}
            >
              <td className="border-left-none padding-y-12 feature-label">
                <>
                  {eachBooleanFeatures.label} &nbsp;
                  <PricingTooltip
                    featureContent={eachBooleanFeatures.tooltip}
                  />
                </>
              </td>
              {plans.map((eachPlanObj) => (
                <td
                  className="padding-y-12"
                  key={`${eachBooleanFeatures.label}_${eachPlanObj.id}`}
                >
                  <img
                    src={`${
                      eachBooleanFeatures.plans.includes(
                        eachPlanObj.codename.toLowerCase()
                      )
                        ? blueCheck
                        : none
                    }`}
                    alt={`${eachBooleanFeatures.label} exists or not in ${eachPlanObj?.codename} plan`}
                  />
                </td>
              ))}
              <td className="padding-y-12">
                <img
                  src={blueCheck}
                  alt={`${eachBooleanFeatures.label} exists or not in Enterprise plan`}
                />
              </td>
            </tr>
          );
        })}
    </table>
  );
};

export default PricingDesktop;
