import React from "react";
const PricingMobileColumn = ({
  eachPlanObj,
  tickFeatures,
  buyButton,
  isEnterprise,
}) => {
  const bandwidthValidityRow = () => {
    return (
      <>
        <strong className="highlight-feature">
          {`${eachPlanObj.bandwidth} and ${eachPlanObj.validity}`}
        </strong>
        <div>
          {eachPlanObj.approxHoursNumeric ? (
            `Estimated hours~${eachPlanObj.approxHoursNumeric} Streaming based on 800kbps video`
          ) : (
            <>&#8212;</>
          )}
        </div>
      </>
    );
  };

  const storageRow = () => {
    return (
      <>
        <strong className="highlight-feature ">
          {`${eachPlanObj.max_storage} Storage`}
        </strong>
        <p>
          *Accounted storage can be 2 to 4 times of uploads, for new files for
          each device.
        </p>
      </>
    );
  };

  const tickFeaturesRow = ({ eachTickFeatures }) => {
    if (!isEnterprise) {
      return eachTickFeatures.plans.includes(
        eachPlanObj.codename.toLowerCase()
      ) ? (
        <div
          className="pricing-column-row"
          key={`${eachPlanObj.codename}-${eachTickFeatures.label}`}
        >
          {eachTickFeatures.label}
        </div>
      ) : null;
    }
    return (
      <div
        className="pricing-column-row"
        key={`enterprise-${eachTickFeatures.label}`}
      >
        {eachTickFeatures.label}
      </div>
    );
  };

  return (
    <div className="pricing-column" id={eachPlanObj.codename}>
      {/*  Heading */}
      <div className={`heading pricing-column-row`}>{eachPlanObj.codename}</div>

      {/* Non-Discounted Amount*/}
      {!isEnterprise && eachPlanObj.non_discount_amount_usd && (
        <div className="non-discount-amt sub-value pricing-column-row border-y-none">
          {`$ ${eachPlanObj.non_discount_amount_usd} /year`}
        </div>
      )}

      {/* Discount */}
      <div className="discount border-y-none pricing-column-row">
        <span className="amt">
          {!isEnterprise ? `$${eachPlanObj.amount_usd}` : "Custom"}
        </span>
        <span className="per-year">
          {!isEnterprise ? (
            <>/year</>
          ) : (
            <>
              <br />
              <span className="per-year">for larger scale</span>
            </>
          )}
        </span>
      </div>

      {/* Free Trial button */}
      <div className=" border-y-none pricing-column-row">
        {!isEnterprise ? (
          <a href={buyButton.href} className="plan-buy-button">
            {buyButton.text}
          </a>
        ) : (
          <a href="/site/contact" className="plan-buy-button">
            Contact Us
          </a>
        )}
      </div>

      {/* Bandwidth,  Validity  and Estimated Hours*/}
      {!isEnterprise ? (
        <div className={` pricing-column-row border-y-none `}>
          {bandwidthValidityRow()}
        </div>
      ) : null}

      {/* Storage */}
      {!isEnterprise ? (
        <div className={`pricing-column-row `}>{storageRow()}</div>
      ) : null}

      {/* Extra Bandwidth and extra Storage */}
      {!isEnterprise ? (
        <div className="pricing-column-row border-top">
          {eachPlanObj.overageBandwidth && eachPlanObj.overageStorage ? (
            `Extra Bandwidth/Storage Price: Bandwidth-$${eachPlanObj.overageBandwidth}/GB & Storage-$${eachPlanObj.overageStorage}/GB.Card is never auto-charged. Bandwidth unit
      price reduces for bulk purchase`
          ) : (
            <>&#8212;</>
          )}
        </div>
      ) : null}

      {/* Enterprise highlights */}
      {isEnterprise ? (
        <div className={` pricing-column-row border-bottom`}>
          <ul>
            <li>Account Manager</li>
            <li>Whatsapp & Call Support</li>
            <li>Scale to 10 Million Viewers</li>
            <li>Private APIs & User Based Analytics</li>
            <li>SLA, 99.94% uptime</li>
            <li>Better Bandwidth price</li>
            <li>Multi-Tenant Features for Course/Website Builders</li>
          </ul>
          <a href="https://www.vdocipher.com/enterprise/" target="_blank">
            More Details here
          </a>
        </div>
      ) : null}

      {/* Tick Features */}
      {tickFeatures.map((eachTickFeatures) => {
        return tickFeaturesRow({ eachTickFeatures });
      })}

      {/* Free Trial button */}
      <div className=" border-y-none pricing-column-row">
        {!isEnterprise ? (
          <a href={buyButton.href} className="plan-buy-button">
            {buyButton.text}
          </a>
        ) : (
          <a href="/site/contact" className="plan-buy-button">
            Contact Us
          </a>
        )}
      </div>

      {/* End */}
    </div>
  );
};

export default PricingMobileColumn;
