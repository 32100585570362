import React, { useState, useEffect } from "react";
import PricingTrial from "../../components/pricing-components/pricing-trial";
import PricingDesktop from "./pricing-desktop";
import PricingMobile from "./pricing-mobile";
import { getLoginId } from "../../utils/user/info-utils";
import blueDoubleGt from "../../svg/svgs/blue-double-gt.svg";
import greenDoubleGt from "../../svg/svgs/green-double-gt.svg";

function PricingTable({ plans, tickFeatures }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleScreenWidth = () => setScreenWidth(window.innerWidth);
  const [buyButton, setBuyButton] = useState({
    text: "Free Trial",
    href: "/dashboard/register",
  });
  const enterprisePlanObj = {
    codename: "enterprise",
  };
  const mobileDesktopProps = {
    plans,
    tickFeatures,
    buyButton,
    enterprisePlanObj,
  };

  useEffect(() => {
    if (getLoginId()) {
      setBuyButton({
        text: "Buy Now",
        href: "/dashboard/account",
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidth);
    return window.addEventListener("resize", handleScreenWidth);
  }, []);

  return (
    <div>
      <div className="pricing-links">
        <h4 className="pricing-bandwidth-quote">
          <a
            href="https://www.vdocipher.com/blog/vdocipher-price-estimate/"
            target="_blank"
            rel="noreferrer"
            className="text-purple"
          >
            <img
              src={blueDoubleGt}
              height="14px"
              alt="Double Arrow Pointing to Text"
            />
            Pricing Calculator: Estimate Total & Per User Costs
          </a>
        </h4>
        <h4 className="pricing-bandwidth-quote">
          <a
            href="https://calendly.com/vdocipher/pricing-discussion-demo/"
            target="_blank"
            rel="noreferrer"
            className="text-green"
          >
            <img
              src={greenDoubleGt}
              height="14px"
              alt="Double Arrow Pointing to Text"
            />
            Schedule a Demo & Pricing Discussion Call
          </a>
        </h4>
      </div>
      <div className="full-screen-border"></div>

      {screenWidth < 992 && <PricingMobile {...mobileDesktopProps} />}

      {screenWidth >= 992 && <PricingDesktop {...mobileDesktopProps} />}

      <PricingTrial />
    </div>
  );
}
export default PricingTable;
