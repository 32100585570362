import React from "react";
import PricingMobileColumn from "./pricing-mobile-column";
import PricingLite from "./pricing-lite";

const PricingMobile = ({
  plans,
  tickFeatures,
  buyButton,
  enterprisePlanObj,
}) => {
  return (
    <div className="pricing-columns-container">
      <PricingLite buyButton={buyButton} />
      {plans.map((eachPlanObj, index) => {
        return (
          <PricingMobileColumn
            eachPlanObj={eachPlanObj}
            tickFeatures={tickFeatures}
            buyButton={buyButton}
            enterprisePlan={false}
            key={eachPlanObj.id}
          />
        );
      })}
      <PricingMobileColumn
        eachPlanObj={enterprisePlanObj}
        tickFeatures={tickFeatures}
        buyButton={buyButton}
        isEnterprise={true}
      />
    </div>
  );
};

export default PricingMobile;
